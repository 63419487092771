
import { AdminClient, GameClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class AppConfiguration extends Vue {

    isGalleryPublished: boolean = false;
    isChallengePublished: boolean = false;
    isGadgetPublished: boolean = false;
    imgUrl: string = "";

    localFile: File = null;

    created() {
        this.init();
    }

    init() {
        Promise.all([
            // AdminClient.getGalleryStatus(),
            // AdminClient.getChallengeStatus(),
            AdminClient.getGadgetStatus(),
            // AdminClient.getGadgetImagePath()
        ])
        .then(xs => {
            // this.isGalleryPublished = xs[0];
            // this.isChallengePublished = xs[1];
            this.isGadgetPublished = xs[0];
            // this.imgUrl = xs[1];
        })
    }

    // async saveImgGadget(){
    //     await AdminClient.updateGadgetImage(this.localFile)
    //     .then(x =>{
    //         this.init();
    //     })
    // }
    
    // toggleGalleryConfiguration() {
    //     AdminClient.toggleGalleryPublished()
    //     .then(x => {
    //         this.isGalleryPublished = !this.isGalleryPublished;
    //     })
    // }
    
    // toggleChallengeConfiguration() {
    //     AdminClient.toggleChallengePublished()
    //     .then(x => {
    //         this.isChallengePublished = !this.isChallengePublished;
    //     })
    // }

    toggleGadgetPublished() {
        AdminClient.toggleGadgetPublished()
        .then(x => {
            this.isGadgetPublished = !this.isGadgetPublished;
        })
    }

}
